<template>
  <div class="news-detail view-container">
    <!-- 面包屑 -->
    <BreadCrumb class="bread-crumb"></BreadCrumb>
    <div class="article">
      <p class="article-title">
        {{ $t('2021.7.18-2021.7.25 四川凉山州彝族自治州冕宁县走访报告') }}
      </p>
      <p class="article-time">2021/08/16</p>
      <div class="article-body">
        <p class="article-para">
          {{ $t('2021.7.18，我们从北京出发抵达成都，7.19从成都乘坐长途大巴，历时6小时，抵达凉山彝族自治州的冕宁县，开始为期4天的贫困学生家访活动。本次走访共计10个村子，13户学生，由神州数字基金会与一个村小共同执行。') }}
        </p>
        <p class="article-para">
          {{ $t('得益于国家扶贫工作的大力开展，凉山州与我们想象中的贫穷落后已十分不一样。据当地人介绍，过去常见的「普遍贫困」现象已经不复存在。经过几天的走访我们也发现，正所谓「不幸的家庭各有各的不幸」，学生家庭的贫困原因主要由家庭引起，原因各不相同，有的是因病返穷，有的是单亲家庭，有的是缺乏劳动力……许多学生的父母并不识字，哥哥姐姐仍有辍学情况，但到学生这一代情况已有好转。') }}
        </p>
        <p class="article-h3">
          {{ $t('傍晚时分的冕宁县城 | 彝文/汉语/英语的三语牌匾') }}
        </p>
        <div class="article-img-small">
          <img
            src="@/assets/image/news-detail/image1.png"
            alt=""
            v-if="$store.state.app.lang === 'zh-CN'">
          <img
            src="@/assets/image/news-detail/special1.png"
            alt=""
            v-if="$store.state.app.lang !== 'zh-CN'">
        </div>
        <p class="article-h2">
          {{ $t('· 学生小布的家') }}
        </p>
        <p class="article-para">
          {{ $t('第一天我们去的是位于冕宁县的大桥镇的小布家，从县城坐大巴车还需要一个多小时才能到。汽车在曲折的山路一直颠簸，蹦蹦跳跳，同伴说都不用去迪士尼了，这好像是坐了一个多小时的过山车。') }}
        </p>
        <p class="article-para">
          {{ $t('汽车停在刘伯承红军小学门前，据说是红军长征时修建的，后来几经改建，如今还有学生就读。') }}
        </p>
        <p class="article-h3">
          {{ $t('村子风景 1 | 刘伯承红军小学') }}
        </p>
        <div class="article-img-small">
          <img src="@/assets/image/news-detail/image2.png" alt="">
        </div>
        <p class="article-para">
          {{ $t('学生小布的妈妈在路边等我们，她们是彝族，丈夫在外打工，妈妈在家一边做农活，一边照顾孩子们。家里一共有三个孩子，两个妹妹还在读初中，大哥今年刚刚高考完，上了一本线，第一志愿报的是西南交大的自动化专业。被问及为什么报自动化时，大哥说听说自动化毕业后赚钱多。小布的爸爸在外打工，好的时候一年能赚3万块，去年只赚了1.9万，今年至今为止还没有找到活，最近去了理塘帮人盖房子。') }}
        </p>
        <p class="article-para">
          {{ $t('生活尽管清苦，但小布的妈妈十分乐观开朗，因为自己和丈夫不识字，她很重视孩子们的教育。「不会重男轻女，只要她们读书，我就供。」小布妈妈说。') }}
        </p>
        <p class="article-para">
          {{ $t('临走时，她问我们是否还要走访其它学生，并对我们说：「如果有比我们条件更困难的，我还可以撑一撑，你们可以先帮助别人。」') }}
        </p>
        <p class="article-h3">
          {{ $t('学生小布的家') }}
        </p>
        <div class="article-img-small">
          <img src="@/assets/image/news-detail/image3.png" alt="">
        </div>
        <div v-if="$store.state.app.lang !== 'zh-CN'">
          <p class="article-h3">
            We walk on the road
          </p>
          <div class="article-img-small">
            <img src="@/assets/image/news-detail/special2.png" alt="">
          </div>
        </div>
        <p class="article-h2">
          {{ $t('· 学生小丽的家') }}
        </p>
        <p class="article-para">
          {{ $t('学生小丽的家在冕宁县的庙高山村，我们一早坐了三轮车从县城出发。') }}
        </p>
        <p class="article-h3">
          {{ $t('这种三轮车是我们主要的代步工具。') }}
        </p>
        <div class="article-img-small">
          <img src="@/assets/image/news-detail/image4.png" alt="">
        </div>
        <p class="article-para">
          {{ $t('小丽的个头不高，性格有些内向，今年读初二了。父亲在外地打工，姐姐在西昌念职校，母亲很早就消失了，有人说是被拐了，有人说是跑了。小丽常年自己在家，独自的生活锻炼了小丽的自立，院子打扫的很干净，我们去的时候她正在门边自己预习初三的课程。我们问小丽能看懂吗，她说有的看不懂，看不懂的地方就多看几遍。我们给她带了点零食，小丽说自己不爱看电视，自己在家无聊了就学习。') }}
        </p>
        <p class="article-h3">
          {{ $t('小丽在门边学习的书桌 | 小丽家的院子，打扫的十分干净') }}
        </p>
        <div class="article-img-small">
          <img src="@/assets/image/news-detail/image5.png" alt="">
        </div>
        <p class="article-para">
          {{ $t('小丽有几个亲戚也住在村子里，但平时不太走动，小丽跟邻居更熟一些。家里的地也给邻居种，小丽可以随时去摘菜回家炒菜。父亲常年在外，说如果需要可以去同村的姑父那里拿钱，等父亲回来还账。但小丽说自己几乎不去，一般都用自己的压岁钱等。') }}
        </p>
        <p class="article-h3">
          {{ $t('这是小丽自己做的装饰，她说以前邻居家养孔雀，她捡的孔雀羽毛自己做的。') }}
        </p>
        <div class="article-img-big">
          <img src="@/assets/image/news-detail/image6.png" alt="">
        </div>
        <div v-if="$store.state.app.lang !== 'zh-CN'">
          <p class="article-h3">
            Picture with Li
          </p>
          <div class="article-img-big">
            <img src="@/assets/image/news-detail/special3.png" alt="">
          </div>
        </div>
        <p class="article-h2">
          {{ $t('· 学生小俊的家') }}
        </p>
        <p class="article-para">
          {{ $t('小俊的梦想是成为一名军人，已经考上了高中。他的家一边是新屋，一边是老屋。他和大伯住在新屋，妈妈住在老屋。老屋几乎要塌了。当时为了盖新屋，小俊家欠下了二十万的债，他的父亲三四年前因肝癌去世，看病也花了将近七八万，家里已经负债累累。一直瘫痪在床的奶奶在小俊父亲去世后四个月也去世了。家里还有一个脚残疾的大伯，一直没有成家，跟着小俊和小俊妈妈生活。') }}
        </p>
        <p class="article-h3">
          {{ $t('妈妈住的老屋 | 小俊和大伯的房间望出去，是老屋的屋顶') }}
        </p>
        <div class="article-img-small">
          <img
            src="@/assets/image/news-detail/image7.png"
            alt=""
            v-if="$store.state.app.lang === 'zh-CN'">
          <img
            src="@/assets/image/news-detail/special4.png"
            alt=""
            v-if="$store.state.app.lang !== 'zh-CN'">
        </div>
        <p class="article-para">
          {{ $t('小俊的家似乎勉强支撑到建起来，便没有任何闲钱装修了。父亲去世至今，家里也没有添置任何家具，地上虽然铺了光洁的大理石地板，墙面却仍是毛坯样的水泥墙。「可把我害惨了。」提起自己的生活，小俊妈妈不禁落下了眼泪，说：「生活把我打败了，我投降了。」') }}
        </p>
        <p class="article-para">
          {{ $t('为了小俊，小俊妈妈没有再婚，也没有外出打工，主要在家务农。上个月，她们刚刚卖了一季的蚕，赚了2000元，小俊妈妈说给小俊攒高中学费。她还说等小俊读了大学，她就进城去打工。') }}
        </p>
        <div v-if="$store.state.app.lang !== 'zh-CN'">
          <p class="article-h3">
            Looking out of the room of Jun and Uncle, it is the roof of the old house
          </p>
          <div class="article-img-small">
            <img
              src="@/assets/image/news-detail/special5.png"
              alt=""
              v-if="$store.state.app.lang !== 'zh-CN'">
          </div>
        </div>
        <p class="article-h2">
          {{ $t('· 学生小英的家') }}
        </p>
        <p class="article-para">
          {{ $t('小英跟着爷爷奶奶生活，但和其他学生不同，并不是因为父母在外务工。她的父亲就住在爷爷奶奶家不远处的山上，但父亲一直有酗酒的毛病，小英最小的妹妹只八个月大，父亲就把孩子扔给爷爷奶奶了。爷爷说父亲娶过两任妻子，第一任结婚没几天，发现他酗酒后便走了，第二任妻子生下了小英三姐妹也走了。') }}
        </p>
        <p class="article-para">
          {{ $t('「他喝了酒就到处乱跑，像脑子不太清醒一样。」爷爷提起自己的儿子，颇有恨铁不成钢的无奈。') }}
        </p>
        <div v-if="$store.state.app.lang === 'zh-CN'">
          <p class="article-h3">
            {{ $t('父亲自己住的老屋 | 小英的房间') }}
          </p>
          <div class="article-img-small">
            <img
              src="@/assets/image/news-detail/image8.png"
              alt="">
          </div>
        </div>
        <div v-if="$store.state.app.lang !== 'zh-CN'">
          <p class="article-h3">
            The old house where Ying’s father lives
          </p>
          <div class="article-img-small">
            <img
              src="@/assets/image/news-detail/special6.png"
              alt="">
          </div>
        </div>
        <p class="article-para">
          {{ $t('小英在小学五年级时得了紫癜，因此曾休学过一年，如今还欠着2万块的医药费。小叔家的孩子也由爷爷奶奶照看，小叔家的妹妹天生近视，也做了手术，现在还带着小眼镜。') }}
        </p>
        <p class="article-para">
          {{ $t('父亲的酗酒问题使整个家的气氛都十分压抑，与爷爷奶奶一起生活的代沟问题明显。一家人的生活收入主要靠爷爷，两亩地租给了老板建大棚、种葡萄，一亩地租金1300元，一年2600元。其他地用来种农作物，自给自足，小英两姐妹都在读初中，爷爷说别人都给100元/周的生活费，他们给不起，两姐妹一起150元/周。') }}
        </p>
        <div v-if="$store.state.app.lang !== 'zh-CN'">
          <p class="article-h3">
            Ying’s room、Grandparents’ home
          </p>
          <div class="article-img-small">
            <img
              src="@/assets/image/news-detail/special7.png"
              alt="">
          </div>
        </div>
        <div v-if="$store.state.app.lang === 'zh-CN'">
          <p class="article-h2">
            {{ $t('· 学生小龙的家') }}
          </p>
          <p class="article-para">
            {{ $t('小龙的家离县城不算太远，我们过去时正好有一群村民乘凉，听到我们是要去小龙家，很热情地给我们带路过去。小龙今年读初二，黑黑的，很朴实很可爱。') }}
          </p>
          <p class="article-h3">
            {{ $t('村民带我们去小龙家') }}
          </p>
          <div class="article-img-big">
            <img src="@/assets/image/news-detail/image9.png" alt="">
          </div>
          <p class="article-para">
            {{ $t('小龙的父亲不久前因车祸去世。我们去的时候小龙的妈妈不在家，小龙的嫂子带着弟弟妹妹在家。嫂子说哥哥本来要去打工的，但是苞米收获的季节马上就要到了，因此留下来做农忙。小龙说哥哥本来成绩很好，只是高中时父母都出去打工，留哥哥自己在家，哥哥便沉迷手机荒废了学业，高中没读完就去广东打工了。') }}
          </p>
          <p class="article-h3">
            {{ $t('小龙的家') }}
          </p>
          <div class="article-img-small">
            <img src="@/assets/image/news-detail/image10.png" alt="">
          </div>
          <p class="article-para">
            {{ $t('嫂子正在怀二胎，说自己小学没毕业便辍学去打工了。提到自己，嫂子很后悔没能好好读书。「那个时候都是流行打工，都觉得读书没用。」嫂子如此说。她和哥哥便是在广东一起打工时认识的。父亲的去世家里失去了主要的劳动力之一，嫂子说妈妈应该也不会出去打工了，不然这么多孩子嫂子自己看不过来。') }}
          </p>
          <p class="article-para">
            {{ $t('我们等了一会，小龙的妈妈还没回来，于是便起身告辞。在山路上，见到一个女人骑着三轮车与我们错身而过，不多时，便接到小龙妈妈打来的电话。原来刚刚与我们错过的女人便是小龙妈妈。') }}
          </p>
        </div>
        <div v-if="$store.state.app.lang === 'zh-CN'">
          <p class="article-h2">
            · 一些其他的照片
          </p>
          <p class="article-para">
            烟叶是当地的主要经济作物之一，七八月正是烤烟的季节，因此许多村在天气预报有冰雹时会放炮，将雨云赶走。
          </p>
          <p class="article-para">
            下面的图是学生家中的烤烟房，烟叶要烤足足7天，温度不能变化，因此父亲要一直睡在烤烟房旁边确保温度。
          </p>
          <div class="article-img-small">
            <img src="@/assets/image/news-detail/other1.png" alt="">
          </div>
          <p class="article-para">
            这条路远远地通往半山，我们走错了路，一直爬到这条路的尽头，才发现原来学生家其实在山脚下，于是又走了下来。这里海拔已有2000m，稍微走几步路便气喘吁吁。
          </p>
          <div class="article-img-small">
            <img src="@/assets/image/news-detail/other2.png" alt="">
          </div>
          <p class="article-para">
            这是位于县城的「城中村」，许多家庭会利用空闲的一两亩地种植水稻，自给自足。
          </p>
          <div class="article-img-small">
            <img src="@/assets/image/news-detail/other3.png" alt="">
          </div>
          <p class="article-para">
            许多学生的家中都是这样「家徒四壁」的情况，仅仅只有一间毛坯房，里面却没有任何家具。
          </p>
          <div class="article-img-small">
            <img src="@/assets/image/news-detail/other4.png" alt="">
          </div>
          <p class="article-para">
            摩的和三轮都是我们在各个村子间的主要交通工具。
          </p>
          <div class="article-img-big">
            <img src="@/assets/image/news-detail/other5.png" alt="">
          </div>
          <p class="article-para">
            有一个学生的姐姐养了很多花，用旧轮胎自己做成了花盆。
          </p>
          <div class="article-img-big">
            <img src="@/assets/image/news-detail/other6.png" alt="">
          </div>
        </div>
        <p class="article-h2">
          {{ $t('· 总结') }}
        </p>
        <p class="article-para">
          {{ $t('经过这次走访，我们也可以看出，生活的贫困与否有时不能由自己决定，但如果家庭健全，父母都是积极乐观的人，学生也会开朗许多。而如果是一直抱怨贫困，或者留守儿童、跟随爷爷奶奶生活的学生，性格多半比较内向腼腆，甚至会叛逆。无论在什么时候，都不应该放弃对美好生活的希望与追求，也只有这样生活才能越过越好。也祝愿这次走访的家庭都能早日走出困境。') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>

<style scoped lang="less">
.news-detail {
  padding-top: 40px;
  .article {
    padding: 60px 0 120px;
    &-title {
      font-size: 30px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 30px;
      text-align: center;
    }
    &-time {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #6D6E77;
      line-height: 16px;
      text-align: center;
      margin: 20px 0;
    }
    &-body {
      padding-top: 40px;
      border-top: 1px dashed #D6D6D6;
    }
    &-para {
      font-size: 16px;
      color: #6D6E77;
      line-height: 28px;
      margin-bottom: 40px;
    }
    &-h2 {
      font-size: 24px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #1D2130;
      line-height: 24px;
      margin-bottom: 40px;
    }
    &-h3 {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #1D2130;
      line-height: 16px;
      margin-bottom: 40px;
    }
    &-img-small {
      width: 100%;
      height: 340px;
      margin: 0 auto 40px;
      > img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    &-img-big {
      width: 100%;
      height: 490px;
      margin: 0 auto 40px;
      > img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
}
@media (max-width: 750px) {
  .news-detail {
    padding-top: 0;
    .bread-crumb {
      height: 35px;
      line-height: 35px;
      background: #F5F5F5;
      margin: 0 -15px;
      padding: 0 15px;
    }
    .article {
      padding: 15px 0 30px;
      &-title {
        font-size: 20px;
        line-height: 28px;
        text-align: left;
      }
      &-time {
        font-size: 14px;
        color: #686868;
        line-height: 14px;
        text-align: left;
        margin: 16px 0;
      }
      &-body {
        padding-top: 24px;
        border-top: 1px solid #d8d8d8;
      }
      &-para {
        font-size: 14px;
        color: #686868;
        line-height: 20px;
        margin-bottom: 15px;
      }
      &-h2 {
        font-size: 17px;
        color: #333;
        line-height: 23px;
        margin-bottom: 10px;
      }
      &-h3 {
        font-size: 14px;
        color: #333;
        line-height: 18px;
        margin-bottom: 10px;
      }
      &-img-small {
        width: 100%;
        height: 100px;
        margin: 0 auto 20px;
        > img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      &-img-big {
        width: 100%;
        height: 184px;
        margin: 0 auto 20px;
        > img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
